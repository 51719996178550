/* eslint-disable @typescript-eslint/naming-convention */
import { MaintUser } from './../providers/servicer/models/maint-user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Environment } from '../../environments/environment';

import { Station } from './servicer/models/station';
import { UserLoginV2ApiResponse } from './servicer/models/user-login-v2-api-response';
import { UserOption } from './servicer/models/user-option';
import { Cargo } from './servicer/models/cargo';
import { RoutingAdditionalInfo } from './servicer/models/routing-additional-info';
import { UserStationsV2ApiResponse } from './servicer/models/user-stations-v2-api-response';
import { UserVehiclesV2ApiResponse, UserVehiclesInfoV2ApiResponse } from './servicer/models/user-vehicles-v2-api-response';
import { UserPuStationCodesV2ApiResponse } from './servicer/models/user-pu-station-codes-v2-api-response';
import { UserDoStationCodesV2ApiResponse } from './servicer/models/user-do-station-codes-v2-api-response';
import { Const } from './const';
import { UserOptionItemNameType } from 'src/app/types/user-option-item-name-type';
import { UserAccoountServiceProvider } from 'src/app/providers/servicer/user-account-service';
import { GenericItem } from 'src/app/providers/servicer/models/generic-item';

import { TransitionStatusService } from './transition-status.service';
import { ModalControl } from 'src/app/services/modal-control';
import { ModalOption } from './servicer/models/modal-option';
import { FacilityV1ApiResponse, FacilitySettingV1ApiResponse, FacilitySummaryViewHistoryV1ApiResponse, FacilityDetailViewHistoryV1ApiResponse, FacilityReserveHistoryV1ApiResponse  } from './serverless-api/models/facility-v1-api-response';

@Injectable({ providedIn: 'root' })
export class HttpRequestor {

  /** ログイン時のユーザオプション. */
  userOption: UserOption;

  /** ステーションの情報. */
  station = {
    /** ステーションのリスト. */
    stations: [] as Station[],
    /** 必須ステーション. */
    requiredStation: null as Station,
    /** 必須ステーション範囲（単位 : メートル）. */
    requiredStationRadiusDistance: null as number,
    /** 自宅のステーション. */
    homeStation: null as Station,
    /** プライベートステーションのリスト. */
    privateStations: [] as Station[]
  };

  public loginResponse = new Subject();
  public errorResponse = new Subject();

  public errorCode: number;

  private base_url = Environment.baseUrl;
  // 満空情報関連API呼び出し時のURL
  private base_url_for_vacancy = Environment.baseUrlForVacancy;

  constructor(
    public httpClient: HttpClient,
    private userAccoountServiceProvider: UserAccoountServiceProvider,
    public transitionStatus: TransitionStatusService,
    private modalControl: ModalControl
  ) { }


  /**ログインのリクエスト.
   *
   * @param loginId ログインID
   * @param passwd パスワード
   * @param language 言語
   * @param app_version appバージョン
   * @param callback コールバック
   * @param auto 自動ログイン
   */
  postLoginRequest(
    loginId: string,
    passwd: string,
    language: string,
    app_version: string,
  ) {
    const url = this.base_url + 'login';
    this.userOption = null;

    // POSTする際のパラメータ値
    const postParams =
      'login_id=' + loginId +
      '&password=' + passwd +
      '&language=' + language +
      '&app_version=' + app_version;

    this.loginPostRequestObservable(url, postParams).subscribe((res: HttpResponse<UserLoginV2ApiResponse>) => {
      if(res.body.result === Const.HTTP_RESPONSE_RESULT_SUCCESS) {
        this.postStationsRequest(res.body.user_id.toString()).subscribe(() => {
          this.loginResponse.next(res);
        },
        (error: HttpErrorResponse) => {
          this.errorResponse.next(error.status);
        });
      } else {
        this.loginResponse.next(res);
      }
    },
      (error: HttpErrorResponse) => {
        console.log(error.status);
        let modalOption: ModalOption;
        this.errorCode = error.status;
        switch (error.status) {
          case 400:
          case 403:
          case 404:
          case 500:
          case 502:
          case 503:
            //クライアントエラー画面を表示
            modalOption = {
              title: Const.ERROR_MODAL_TITLE_NO_ACCESS,
              message: Const.ERROR_MODAL_TEXT_RETRY_ACCESS,
              isCallCenter: true
            };
            this.modalControl.openModal(Const.MODAL_TYPE_ERROR, modalOption);
            break;

          case 401:
          case 408:
          case 504:
            //エラーメッセージを表示（401）、通信障害画面を表示（408,504）
            //圏外かつモーダルを1度も表示していない場合、モーダルを表示できないためmodalControlを利用せずにログイン画面で処理
            this.errorResponse.next(error.status);
            break;

          default:
            //例外発生
            //サーバーエラー画面を表示
            modalOption = {
              title: Const.ERROR_MODAL_TITLE_NO_ACCESS,
              message: Const.ERROR_MODAL_TEXT_RETRY_ACCESS,
              isCallCenter: true
            };
            this.modalControl.openModal(Const.MODAL_TYPE_ERROR, modalOption);
            break;
        }
      });
  }

  /**ユーザステーション一覧取得.
   * @param userId ユーザID
   * @returns Observable<UserStationsV2ApiResponse>
   */
  postStationsRequest(
    userId: string
  ): Observable<UserStationsV2ApiResponse> {
    const url = this.base_url + 'stations';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserStationsV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }

        this.setStation(response.stations);

        return response;
      }));
  }

  /**[ユーザ]近地乗車ステーション一覧取得（v2/user/puStationCodes）.
   * @param userId ユーザID
   * @param lat 緯度
   * @param lon 経度
   * @returns Observable<UserPuStationCodesV2ApiResponse>
   */
  postPuStationCodesRequest(userId: string, lat: number, lon: number): Observable<UserPuStationCodesV2ApiResponse> {
    const url = this.base_url + 'puStationCodes';

    // POSTする際のパラメータ値
    const postParams =
      'userId=' + userId +
      '&lat=' + lat +
      '&lon=' + lon;

    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserPuStationCodesV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  /*** [ユーザ]降車ステーション一覧取得（v2/user/doStationCodes）.
   * @param userId ユーザID
   * @param lat 緯度
   * @param lon 経度
   * @returns Observable<UserDoStationCodesV2ApiResponse>
   */
  postDoStationCodesRequest(userId: string, puStationCode: string): Observable<UserDoStationCodesV2ApiResponse> {
    console.log('postDoStationCodesRequest puStationCode' + puStationCode);
    const url = this.base_url + 'doStationCodes';

    // POSTする際のパラメータ値
    const postParams =
      'userId=' + userId +
      '&puStationCode=' + puStationCode;

    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserDoStationCodesV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  // 車両検索のリクエスト
  postSearchRequest(
    user_id: string,
    lat: string,
    lon: string,
    user_number: string,
    child_count: string,
    adult_count: string,
    cargoCount: string,
    dest_lat: string,
    dest_lon: string,
    language: string,
    additionalInfo: RoutingAdditionalInfo,
    callback: any
  ) {
    const url = this.base_url + 'vehicleSearch';
    const userNum = user_number ? user_number : 0;
    const cargoCnt = cargoCount ? cargoCount : 0;
    const additionalInfoJson = JSON.stringify(this.convertAdditionalInfo(additionalInfo));

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&user_number=' + userNum +
      '&child_count=' + child_count +
      '&adult_count=' + adult_count +
      '&cargoCount=' + cargoCnt +
      '&dest_lat=' + dest_lat +
      '&dest_lon=' + dest_lon +
      '&language=' + language +
      '&additionalInfo=' + encodeURIComponent(additionalInfoJson) +
      '&guidance_destination=false';

    this.postRequest(url, postParams, callback, -1, false);
  }
  // 配車指示のリクエスト
  postDispatchRequest(
    user_id: string,
    vehicle_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'dispatchRequest';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 配車確認のリクエスト
  postDispatchConfirmationRequest(
    user_id: string,
    language: string,
    callback: any,
    lat?: number,
    lon?: number,
    accuracy?: number
  ) {
    const url = this.base_url + 'dispatchConfirmation';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&language=' + language;

    if (lat != null && lon != null && accuracy != null) {
      postParams = postParams + '&lat=' + lat +
        '&lon=' + lon +
        '&accuracy=' + accuracy;
    }

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 目的地到着のリクエスト
  postArrivedDestinationRequest(
    user_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'arrivedDestination';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 配車キャンセルのリクエスト
  postDispatchCancelRequest(
    user_id: string,
    vehicle_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'dispatchCancel';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;
    this.postRequest(url, postParams, callback, -1, false);
  }

  /**
   * 車両一覧取得.
   *
   * @param userId ユーザID
   * @returns Observable<UserVehiclesV2ApiResponse>
   */
  postVehicleListRequest(
    userId: string,
  ): Observable<UserVehiclesV2ApiResponse> {
    const url = this.base_url + 'vehicles';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    return this.postRequestObservable(url, postParams);
  }

  /**
   * 車両情報リスト取得
   *
   * @param userId ユーザID
   * @returns Observable<UserVehiclesInfoV2ApiResponse>
   */
  postVehiclesInfoRequest(
    userId: string,
  ): Observable<UserVehiclesInfoV2ApiResponse> {
    const url = this.base_url + 'vehiclesInfo';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    return this.postRequestObservable(url, postParams);
  }

  // ユーザステータス到着変更のリクエスト
  postUserArrivedRequest(
    user_id: string,
    vehicle_id: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'arrived';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // アンケート設問
  // question_id :
  //  1 (予約前キャンセルアンケート)
  //  2 (予約後キャンセルアンケート)
  //  3 (降車後アンケート)
  postQuestionReceiveServlet(
    user_id: string,
    question_id: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'questionReceive';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&question_id=' + question_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // アンケート投稿
  // question_id :
  //  0 (予約前キャンセルアンケート)
  //  1 (予約後キャンセルアンケート)
  //  2 (降車後アンケート)
  postQuestionAnswerServlet(
    question_id: string,
    user_id: string,
    language: string,
    answers: string,
    callback: any
  ) {
    const url = this.base_url + 'questionAnswer';

    // POSTする際のパラメータ値
    const postParams =
      'question_id=' + question_id +
      '&user_id=' + user_id +
      '&language=' + language +
      '&answers=' + encodeURIComponent(answers);

    this.postRequest(url, postParams, callback, -1, false);
  }
  /*** アカウント情報を取得し、userOptionとstationのデータを再取込
   * @param userId ユーザID
   */
  public fetchUserOptionAndStation(userId: number): Observable<GenericItem<MaintUser>> {
    return this.userAccoountServiceProvider.get(userId).pipe(
      map((response) => {
        this.userOption = {
          homeStationCode: response.item.options.find((v) => v.name === UserOptionItemNameType.HOME_STATION_CODE)?.value,
          privateStationCodes: response.item.options.find((v) => v.name === UserOptionItemNameType.PRIVATE_STATION_CODES)?.value.split(','),
          requestableReservation: response.item.options.find((v) => v.name === UserOptionItemNameType.REQUESTABLE_RESERVATION)?.value,
          requiredStationCode: response.item.options.find((v) => v.name === UserOptionItemNameType.REQUIRED_STATION_CODE)?.value,
          requiredStationRadiusDistance: Number(response.item.options.find(
            (v) => v.name === UserOptionItemNameType.REQUIRED_STATION_RADIUS_DISTANCE
          )?.value),
        } as UserOption;
        return response;
      }),
      map((response) => {
        this.postStationsRequest(userId.toString()).subscribe();
        return response;
      })
    );
  }

  // 予約確認
  postReservationConfirmRequest(
    user_id: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'reservationConfirm';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  //配車履歴取得
  postDispatchHistoryReauest(
    user_id: string,
    language: string,
    callback: any,
    start_dt?: string,
    end_dt?: string,
  ) {
    const url = this.base_url + 'dispatchHistory';

    let postParams =
      'user_id=' + user_id +
      '&language=' + language;

    if (start_dt && end_dt) {
      postParams = postParams +
        '&start_dt=' + start_dt +
        '&end_dt=' + end_dt;
    }

    this.postRequest(url, postParams, callback, -1, false);
  }

  // BLE API
  postBoardingCheckRequest(
    user_id: string,
    vehicle_id: string,
    mp_id: string,
    token: string,
    callback: any
  ) {
    const url = this.base_url + 'boardingCheck';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&mp_id=' + mp_id +
      '&token=' + token;

    this.postRequest(url, postParams, callback, -1, false);
  }

  postPlacePredictions(
    user_id: string,
    input: string,
    callback: any,
    option?: { //候補地としての優先度を上げたい地域を指定
      range?: {
        lat: number;
        lng: number;
        radius: number;
      };
      language?: string;
    }
  ) {
    const url: string = this.base_url + 'placePredictions';

    let param: string =
      'user_id=' + user_id +
      '&input=' + input;
    param = this.setPlacePredictionsParamOption(param, option);

    this.postRequest(url, param, callback, 0, false);
  }

  setPlacePredictionsParamOption(param: string, option: any): string {
    if (option == null) {
      return param;
    }

    if (option.range != null &&
      option.range.lat != null &&
      option.range.lng != null &&
      option.range.radius != null) {
      param = param + '&lat=' + option.range.lat + '&lng=' + option.range.lng + '&radius=' + option.range.radius;
    }

    if (option.language != null && option.language !== '') {
      param = param + '&language=' + option.language;
    }

    return param;
  }

  postPlaceDetails(user_id: string, place_id: string, callback: any, language?: string) {
    const url: string = this.base_url + 'placeDetails';

    let param: string =
      'user_id=' + user_id +
      '&place_id=' + place_id;
    if (language != null) { param = param + '&language=' + language; }

    this.postRequest(url, param, callback, 0, false);
  }

  postReverseGeocode(user_id: string, lat: number, lng: number, callback: any, language?: string) {
    const url: string = this.base_url + 'reverseGeocode';

    let param: string =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lng=' + lng;
    if (language != null) { param = param + '&language=' + language; }

    this.postRequest(url, param, callback, 0, false);
  }

  postUserSettings(user_id: string, language: string, fcm_token: string, callback: any) {
    const url: string = this.base_url + 'settings';

    const param: string =
      'user_id=' + user_id +
      '&language=' + language +
      '&fcm_token=' + fcm_token;

    this.postRequest(url, param, callback, 0, false);
  }

  // ポストリクエスト実行
  postRequest(
    url: string,
    postParams: string,
    callback: any,
    req_sts: number,
    auto: any
  ) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const options = {
      headers: header,
      withCredentials: true
    };

    console.log('send:' + postParams);

    this.httpClient
      .post(url, postParams, options)
      .toPromise()
      .then((data) => {
        try {
          if (data) {
            console.log('post: ' + JSON.stringify(data));
          }
          this.execCallback(callback, data, 0, req_sts, auto);
        } catch (e) {
          console.log(e);
          this.execCallback(callback, data, -1, req_sts, auto);
        }
      })
      .catch((err) => {
        console.log('post error: ' + err);
        this.execCallback(callback, '', -1, req_sts, auto);
      });
  }

  /*** POSTリクエスト実行（Observableで返却）.
   * @param url URL
   * @param params パラメータ
   * @returns Observable
   */
  postRequestObservable<V>(
    url: string,
    params: string
  ): Observable<V> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const options = {
      headers: header,
      withCredentials: true
    };

    return this.httpClient.post<V>(url, params, options);
  }

  /*** POSTリクエスト実行（Observableで返却）.
   * @param url URL
   * @param params パラメータ
   * @returns Observable
   */
   vacancyPostRequestObservable<V>(
    url: string,
    params: {}
  ): Observable<V> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const options = {
      headers: header
    };

    return this.httpClient.post<V>(url, params, options);
  }

  /*** POSTリクエスト実行（Observableで返却）.
   * @param url URL
   * @param params パラメータ
   * @returns Observable
   */
  loginPostRequestObservable<UserLoginV2ApiResponse>(
    url: string,
    params: string
  ): Observable<HttpResponse<UserLoginV2ApiResponse>> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });


    return this.httpClient.post<UserLoginV2ApiResponse>(url, params, { headers: header, observe: 'response', withCredentials: true });
  }

  private execCallback(callback, res, code, req_sts, auto) {
    try {
      callback(res, code, req_sts, auto);
    } catch (e) {
      console.log('execCallback error.');
      console.log(e);
    }
  }

  /*** additionalInfoの変換(null判定).
   * @param additionalInfo 荷物の追加情報
   */
  private convertAdditionalInfo(additionalInfo: RoutingAdditionalInfo): RoutingAdditionalInfo {
    const routingAdditionalInfo: RoutingAdditionalInfo = {
      cargoes: null,
      puStationCode: null,
      doStationCode: null
    };

    if (!additionalInfo) {
      return null;
    }
    if (additionalInfo.cargoes) {
      const cargoExist: Cargo[] = additionalInfo.cargoes.filter(cargo => cargo.cargoId || cargo.ownerId);
      if (cargoExist.length > 0) {
        routingAdditionalInfo.cargoes = cargoExist;
      }
    }
    routingAdditionalInfo.doStationCode = additionalInfo.doStationCode;
    routingAdditionalInfo.puStationCode = additionalInfo.puStationCode;

    return routingAdditionalInfo;
  }

  /*** ステーション関連の情報を設定します.
   * @param originalStations オリジナルのステーションのリスト
   * @returns ステーションのリスト
   */
  private setStation(originalStations: Station[]) {
    this.station.stations = originalStations;

    this.userOption = this.transitionStatus.getRequest();
    if (!this.userOption) {
      return;
    }

    const stations = this.toStationsSetCode(originalStations);

    this.station.requiredStation = this.toStation(this.userOption.requiredStationCode, stations);
    this.station.requiredStationRadiusDistance = this.userOption.requiredStationRadiusDistance;
    this.station.homeStation = this.toStation(this.userOption.homeStationCode, stations);
    this.station.privateStations = this.toStations(this.userOption.privateStationCodes, stations);
  }

  /*** ステーションのリストにコードを設定します.
   * @param originalStations オリジナルのステーションのリスト
   * @returns コードを設定したステーションのリスト
   */
  private toStationsSetCode(originalStations: Station[]): any {
    if (!originalStations) {
      return [];
    }

    const stations = [];

    originalStations.forEach((v) => {
      stations[v.code] = v;
    });

    return stations;
  }

  /*** ステーションのリストに変換します.
   * @param stationCodes ステーションコードのリスト
   * @param originalStations ステーションのリスト
   * @returns ステーションのリスト
   */
  private toStations(stationCodes: string[], originalStations: Station[]): any {
    if (!stationCodes) {
      return [];
    }

    const stations = [];

    stationCodes.forEach((stationCode) => {
      const station = originalStations[stationCode];

      if (station) {
        stations.push(station);
      }
    });
    return stations;
  }

  /*** ステーションに変換します.
   * 以下の場合、null を返却します.
   * - ステーションコードがない
   * - ステーションのリストにステーションコードがない
   *
   * @param stationCode ステーションコード
   * @param stations ステーションのリスト
   * @returns ステーション
   */
  private toStation(stationCode: string, originalStations: Station[]): Station {
    if (!stationCode) {
      return null;
    }

    const station = originalStations[stationCode];

    if (!station) {
      return null;
    }

    return station;
  }

  /*** [満空情報]施設情報（v1/facility/facilityInformation）.
   * @param facilityId 施設ID
   * @param startDt 検索範囲開始日時
   * @param endDt 検索範囲終了日時
   * @param latest 最新取得
   * @returns Observable<FacilityV1ApiResponse>
   */
   postGetFacilitiesRequest(facilityId: number, startDt: string, endDt: string, latest: number): Observable<FacilityV1ApiResponse> {
    const url = this.base_url_for_vacancy + 'facilityInformation';

    // POSTする際のパラメータ値
    const postParams = {
      'facilityId': facilityId,
      'startDt': startDt,
      'endDt': endDt,
      'latest': latest
    }

    return this.vacancyPostRequestObservable(url, postParams)
      .pipe(map((response: FacilityV1ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  /*** [満空情報]施設設定（v1/facility/facilitySetting）.
   * @param facilityId 施設ID
   * @returns Observable<FacilitySettingV1ApiResponse>
   */
   postGetFacilitieSettingRequest(facilityId: number): Observable<FacilitySettingV1ApiResponse> {
    const url = this.base_url_for_vacancy + 'facilitySetting';

    // POSTする際のパラメータ値
    const postParams = {
      'id': facilityId
    }

    return this.vacancyPostRequestObservable(url, postParams)
      .pipe(map((response: FacilitySettingV1ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  /*** [満空情報]満空情報閲覧履歴（v1/facility/facilitySummaryViewHistory）.
   * @param  id 履歴ID
   * @param  userId ユーザーID
   * @param  beginViewDt 閲覧開始日時
   * @param  endViewDt 閲覧終了日時
   * @returns Observable<FacilitySummaryViewHistoryV1ApiResponse>
   */
   postUpdateFacilitySummaryViewHistoryRequest(id: number, userId: number, beginViewDt: number,endViewDt: number): Observable<FacilitySummaryViewHistoryV1ApiResponse> {
    const url = this.base_url_for_vacancy + 'facilitySummaryViewHistory/update';

    // POSTする際のパラメータ値
    const postParams = {
      'id': id,
      'userId': userId,
      'beginViewDt': beginViewDt,
      'endView': endViewDt
    }

    return this.vacancyPostRequestObservable(url, postParams)
      .pipe(map((response: FacilitySummaryViewHistoryV1ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  /*** [満空情報]施設詳細閲覧履歴（v1/facility/facilityDetailViewHistory）.
   * @param  id 履歴ID
   * @param  facilityId 施設ID
   * @param  userId ユーザーID
   * @param  beginViewDt 閲覧開始日時
   * @param  endViewDt 閲覧終了日時
   * @returns Observable<FacilityDetailViewHistoryV1ApiResponse>
   */
   postUpdateFacilityDetailViewHistoryRequest(id: number, facilityId: number, userId: number, beginViewDt: number,endViewDt: number): Observable<FacilityDetailViewHistoryV1ApiResponse> {
    const url = this.base_url_for_vacancy + 'facilityDetailViewHistory/update';

    // POSTする際のパラメータ値
    const postParams = {
      'id': id,
      'facilityId': facilityId,
      'userId':  userId,
      'beginViewDt': beginViewDt,
      'endView': endViewDt
    }

    return this.vacancyPostRequestObservable(url, postParams)
      .pipe(map((response: FacilityDetailViewHistoryV1ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  /*** [満空情報]予約履歴（v1/facility/facilityReserveHistory）.
   * @param  facilityId 施設ID
   * @param  userId ユーザーID
   * @param  pickUpPointCode 乗車地コード
   * @param  pickUpPointName 乗車地名
   * @param  rideCount 乗車人数
   * @param  reserveDt 予約日時
   * @returns Observable<FacilityReserveHistoryV1ApiResponse>
   */
   postUpdateFacilityReserveHistoryRequest(facilityId: number, userId: number, pickUpPointCode: string, pickUpPointName: string, rideCount: number, reserveDt: number): Observable<FacilityReserveHistoryV1ApiResponse> {
    const url = this.base_url_for_vacancy + 'facilityReserveHistory/update';

    // POSTする際のパラメータ値
    const postParams = {
      'facilityId': facilityId,
      'userId': userId,
      'pickUpPointCode': pickUpPointCode,
      'pickUpPointName': pickUpPointName,
      'rideCount': rideCount,
      'reserveDt': reserveDt
    }

    return this.vacancyPostRequestObservable(url, postParams)
      .pipe(map((response: FacilityReserveHistoryV1ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

}
