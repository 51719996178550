export const Environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyA-93pGXryrmQ6Ooqr9ujZRPh_cl12i_oY',
    authDomain: 'nimo-stg-namie.firebaseapp.com',
    projectId: 'nimo-stg-namie',
    storageBucket: 'nimo-stg-namie.appspot.com',
    messagingSenderId: '709896662341',
    appId: '1:709896662341:web:5272146f64c6026973e22c'
  },
  /**
   * マップの切り替え.
   *
   * - none : マップ使用しない
   * - osm : OpenStreetMap
   * - google : Google Map
   *    `index.html` に `<script src="https://maps.googleapis.com/maps/api/js?key=${GoogleMapApikey}" async defer></script>` を指定してください。
   */
  map: 'google',
  /** デフォルト乗車人数. */
  defaultRideCount: 1,
  /** 最小乗車人数. */
  minRideCount: 1,
  /** 最大乗車人数. */
  maxRideCount: 8,
  /** デフォルト荷物数. */
  defaultCargoCount: 1,
  /** 最小荷物数. */
  minCargoCount: 1,
  /** 最大荷物数. */
  maxCargoCount: 3,
  /** 必須乗車地付近にいるかを判断するインターバル(ミリ秒). */
  requiredStationNearInterval: 30000,
  /** 歩行速度（単位 : km/h）デフォルト : 4.8km/h（時速） ※分速の場合、80m/min */
  walkVelocity: 4.8,
  /** サービサー接続用URL. */
  baseUrl: 'https://app.nissa-mslab-dev-test.com/reserve/v2/user/',
  /** 満空情報関連API呼び出し時のURL. */
  baseUrlForVacancy: 'https://ext-rest.nissan-mslab-dev-na.com/v1/facility/',
  /** 環境種別. */
  type: 'devSpoke',
  /** 車両タイムアウト時間. */
  vehicleTimeoutTime: 5,

  /**
   * 住所CSV取得先URL.
   *
   * - 本番リリース時 : https://${fqdn}/reserve/v2/image?name=stop_point/data.csv
   */
  addressCsvUrl: 'https://app.nissa-mslab-dev-test.com/reserve/v2/image?name=stop_point/data.csv',
  /**
   * 住所CSV取得先URL.
   *
   * - 本番リリース時 : https://${fqdn}/reserve/v2/image?name=stop_point/data.csv
   */
  addressOpeningHoursUrl: 'https://app.nissa-mslab-dev-test.com/reserve/v2/image?name=system/opening_hours.txt',
  /**
   * ホーム画面メッセージ取得先URL.
   *
   * - 本番リリース時 :
   */
  addressMessageBoardUrl: 'https://app.nissa-mslab-dev-test.com/reserve/v2/image?name=system/message_board.html',
  /**
   * バージョン番号確認用ファイルURL.
   *
   *- 本番リリース時 ：https://${fqdn}/reserve/v2/image?name=system/nimo-${SERVICER_TYPE}-namie-version.json
   */
  addressVersionUrl: 'https://app.nissa-mslab-dev-test.com/reserve/v2/image?name=system/namie-version.json',
  /** オートコンプリートの有効化. */
  isEnabledAutocomplete: false,
  /** BLEの有効. */
  isEnabledBle: false,
  /** ServiceWorkerModule の登録.（PWA 実行時に true） */
  serviceWorkerRegister: true,

  /** 地図初期表示（緯度）. */
  mapInitialStateLat: 37.496098500,
  /** 地図初期表示（経度）. */
  mapInitialStateLng: 141.000272600,
  /** 地図初期表示（ズーム）. */
  mapInitialStateZoom: 20,

  /** PWA更新中タイムアウト(ms) */
  pwaUpdatingTimeout: 1000 * 15,

  /** PWA関連のモーダル最短表示時間(ms) */
  pwaDisplayTimeout: 1000 * 2,

  /** PWA更新確認中の操作無効化(true:操作可, false:操作不可) */
  operationDuringPwaUpdate: false,
  /** s3相互チェック用アプリバージョン番号 */
  appVersion: '1.12',

  /** 満空情報状態画像パス */
  vacancySystemImageBasePath: 'assets/img/facility-status/',
  /** 施設詳細画像パス */
  facilityImageBasePath: 'assets/img/facility/',
  /** 満空情報状態画像ファイル拡張子 */
  vacancySystemImageExtension: '.png'
};
